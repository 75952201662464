<template>
  <div>
    <v-card flat class="pt-4">
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">REFUNDS HISTORY</h4></v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="info" @click="on_refund(deceased_data)"
               v-if="!saving && Object.keys(deceased_data).length > 0 && deceased_data.is_refund===1">
          Add Refund
        </v-btn>
        <v-progress-circular :size="30" :width="5" color="info" indeterminate
                             v-else></v-progress-circular>
      </v-toolbar>
      <v-simple-table dense class="pt-2">
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-center text-uppercase">Claimant</th>
            <th class="text-center text-uppercase">Fund From</th>
            <th class="text-center text-uppercase">Voucher #</th>
            <th class="text-center text-uppercase">Amount</th>
            <th class="text-center text-uppercase">Check #</th>
            <th class="text-center text-uppercase">Date Released</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in data_refund" :key="item.id">
            <td class="text-center">
              {{ item.claimant }}
            </td>
            <td class="text-center">
              {{ item.fund_from }}
            </td>
            <td class="text-center">
              {{ item.voucher_no }}
            </td>
            <td class="text-center">
              {{ formatPrice(item.amount) }}
            </td>
            <td class="text-center">
              {{ item.check_no }}
            </td>
            <td class="text-center">
              {{ item.date_of_released }}
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <refunding
      v-on:dialog="close_dialog"
      :dialog="dialog_show"
      :deceased_client_id="deceased_client_id"
      :name="name"
      :fsc_no="fsc_no"
      :balance="balance"
    ></refunding>
  </div>
</template>

<script>
  import moment from 'moment'
  import {mapGetters} from 'vuex'
  import Refunding from '../../admin_interface/deceased_with_refunds/Refunding'

  export default {
    components: {
      Refunding,
    },
    props: {
      balance:String,
      data_refund: Array,
      deceased_data: Object,
    },
    computed: {
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
      ...mapGetters('authentication', [
        'branch',
        'branch_id',
        'year',
        'month_start',
        'month_end',
        'month_of',
        'user_id',
        'branch_address',
        'branch_contact_no',
      ]),
    },
    data() {
      return {
        saving: false,

        dialog_show: false,

        deceased_client_id: 0,
        fsc_no: '',
        name: '',
      }
    },
    methods: {
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      on_refund(data) {
        this.dialog_show = true
        this.deceased_client_id = data.id
        this.fsc_no = data.fsc_no
        this.name = data.last_name+', '+data.first_name+' '+data.middle_name
      },
      close_dialog(value) {
        this.dialog_show = value
      },
    },
  }
</script>
