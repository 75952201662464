<template>
  <div>
    <v-card flat class="pa-3 mt-2">
      <v-card-text class="d-flex">
        <v-avatar rounded size="120" class="me-6">
          <v-img :src="cropped"></v-img>
        </v-avatar>
      </v-card-text>

      <v-card-text>
        <v-form ref="form" class="multi-col-validation mt-6">
          <div v-if="!is_admin_special">
            <v-row>
              <v-col md="3" cols="12">
                <v-text-field readonly v-model="branch" label="Branch" dense
                              outlined></v-text-field>
              </v-col>
              <v-col md="3" cols="12">
                <v-text-field
                  v-model="data.fsc_no"
                  label="FSC No."
                  dense
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
              <v-col md="3" cols="12">
                <v-text-field
                  readonly
                  v-model="data.date_of_service"
                  label="Date of Service"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col md="3" cols="12">
                <v-text-field
                  readonly
                  v-model="data.date_of_internment"
                  label="Date of Interment"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-card flat>
                <v-toolbar dense dark color="primary">
                  <v-toolbar-title><h4 class="font-weight-light">DECEASED PERSONAL INFOMATION</h4>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-col md="4" cols="12"></v-col>
                  <v-row>
                    <v-col md="4" cols="12">
                      <v-text-field
                        v-model="data.last_name"
                        label="Last Name"
                        dense
                        outlined
                        readonly
                      ></v-text-field>
                    </v-col>

                    <v-col md="4" cols="12">
                      <v-text-field
                        v-model="data.first_name"
                        label="First Name"
                        dense
                        outlined
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col md="4" cols="12">
                      <v-text-field
                        v-model="data.middle_name"
                        label="Middle Name"
                        dense
                        outlined
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="5">
                      <v-text-field
                        v-model="data.date_of_birth"
                        label="Date of Birth"
                        dense
                        outlined
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-text-field v-model="data.age" label="Age" dense outlined
                                    readonly></v-text-field>
                    </v-col>

                    <v-col cols="12" md="5">
                      <v-text-field
                        v-model="data.date_of_death"
                        label="Date of Death"
                        dense
                        outlined
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="data.religion"
                        dense
                        outlined
                        label="Religion"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field v-model="data.civil_status" label="Civil Status" readonly dense
                                    outlined></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field v-model="data.gender" label="Gender" readonly dense
                                    outlined></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-card flat>
                <v-toolbar dense dark color="primary">
                  <v-toolbar-title
                  ><h4 class="font-weight-light">FAMILY REPRESENTATIVES INFORMATION</h4>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-col md="4" cols="12"></v-col>
                  <v-row>
                    <v-col md="4" cols="12">
                      <v-text-field
                        v-model="data.representatives_name"
                        label="Name"
                        dense
                        outlined
                        readonly
                      ></v-text-field>
                    </v-col>

                    <v-col md="4" cols="12">
                      <v-text-field
                        v-model="data.representatives_relationship"
                        label="Relationship"
                        dense
                        outlined
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col md="4" cols="12">
                      <v-text-field
                        v-model="data.representatives_contact_no"
                        label="Contact #"
                        dense
                        outlined
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col md="12" cols="12">
                      <v-text-field
                        v-model="data.representatives_address"
                        label="Address"
                        dense
                        outlined
                        readonly
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-toolbar dense dark color="primary">
                  <v-toolbar-title><h4 class="font-weight-light">SERVICE DATA INFORMATION</h4>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-col md="4" cols="12"></v-col>
                  <v-row>
                    <v-col cols="12" md="3">
                      <v-text-field v-model="data.casket_type" label="Casket Type" readonly dense
                                    outlined></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field v-model="data.mode_of_service" label="Mode of Service" readonly
                                    dense
                                    outlined></v-text-field>
                    </v-col>
                    <v-col md="3" cols="12"
                           v-if="data.mode_of_service==='Damayan'||data.mode_of_service==='Damayan HG Package'||data.mode_of_service==='Damayan FG Package'">
                      <v-text-field
                        v-model="data.maf_no"
                        label="Maf #"
                        dense
                        outlined
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" cols="12"
                           v-if="data.mode_of_service==='Damayan'||data.mode_of_service==='Damayan HG Package'||data.mode_of_service==='Damayan FG Package'">
                      <v-text-field
                        v-model="data.damayan_branch"
                        label="Damayan Branch"
                        dense
                        outlined
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3"
                           v-if="data.mode_of_service==='Kaagapay'||data.mode_of_service==='Kaagapay Mem'||data.mode_of_service==='Kaagapay IFam'||data.mode_of_service==='Kaagapay Rel'||data.mode_of_service==='Kaagapay N-Rel'">
                      <v-text-field v-model="data.kaagapay_category" label="Kaagapay Category"
                                    readonly
                                    dense
                                    outlined></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3"
                           v-if="data.mode_of_service==='Kaagapay'||data.mode_of_service==='Kaagapay Mem'||data.mode_of_service==='Kaagapay IFam'||data.mode_of_service==='Kaagapay Rel'||data.mode_of_service==='Kaagapay N-Rel'">
                      <v-text-field v-model="data.kaagapay.kmf_no" label="KMF #" readonly
                                    dense
                                    outlined></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3"
                           v-if="data.mode_of_service==='Kaagapay'||data.mode_of_service==='Kaagapay Mem'||data.mode_of_service==='Kaagapay IFam'||data.mode_of_service==='Kaagapay Rel'||data.mode_of_service==='Kaagapay N-Rel'">
                      <v-text-field v-model="data.kaagapay.last_name" label="Kaagapay Last Name"
                                    readonly
                                    dense
                                    outlined></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3"
                           v-if="data.mode_of_service==='Kaagapay'||data.mode_of_service==='Kaagapay Mem'||data.mode_of_service==='Kaagapay IFam'||data.mode_of_service==='Kaagapay Rel'||data.mode_of_service==='Kaagapay N-Rel'">
                      <v-text-field v-model="data.kaagapay.first_name" label="Kaagapay First Name"
                                    readonly
                                    dense
                                    outlined></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3"
                           v-if="data.mode_of_service==='Kaagapay'||data.mode_of_service==='Kaagapay Mem'||data.mode_of_service==='Kaagapay IFam'||data.mode_of_service==='Kaagapay Rel'||data.mode_of_service==='Kaagapay N-Rel'">
                      <v-text-field v-model="data.kaagapay.middle_name" label="Kaagapay Middle Name"
                                    readonly
                                    dense
                                    outlined></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3" v-if="data.mode_of_service==='Tie Up'">
                      <v-text-field v-model="data.tie_ups" label="Tie Ups" readonly dense
                                    outlined></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field v-model="data.description" label="Description" readonly dense
                                    outlined></v-text-field>
                    </v-col>
                    <v-col md="3" cols="12">
                      <v-text-field
                        readonly
                        v-model="casket_price"
                        label="Casket Price"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" cols="12">
                      <v-text-field
                        readonly
                        v-model="data.discount"
                        label="Casket Discount"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" cols="12">
                      <v-text-field
                        readonly
                        v-model="data.casket_price"
                        label="Casket Net Price"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" cols="12">
                      <v-text-field
                        readonly
                        min="0"
                        v-model="data.kilometer"
                        label="Kilometers"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" cols="12">
                      <v-text-field
                        readonly
                        v-model="data.kilometer_excess"
                        label="Kilometers Excess"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" cols="12">
                      <v-text-field
                        readonly
                        min="0"
                        v-model="data.days_of_embalming"
                        label="Day's of Embalming"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" cols="12">
                      <v-text-field
                        readonly
                        v-model="data.embalming_excess"
                        label="Embalming Excess"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field v-model="data.viewing_type" label="Viewing Type" readonly dense
                                    outlined></v-text-field>
                    </v-col>
                    <v-col md="3" cols="12">
                      <v-text-field
                        readonly
                        min="0"
                        v-model="data.chapel_view"
                        label="Viewing Days"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" cols="12">
                      <v-text-field
                        readonly
                        v-model="data.viewing_excess"
                        label="Viewing Excess"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" cols="12">
                      <v-text-field
                        readonly
                        min="0"
                        v-model="data.shipment_fee"
                        label="Shipment Fee"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" cols="12">
                      <v-text-field
                        readonly
                        min="0"
                        v-model="data.tribute_fee"
                        label="Tribute Fee"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" cols="12">
                      <v-text-field
                        readonly
                        min="0"
                        v-model="data.hospital_bill"
                        label="Hospital Bill"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" cols="12">
                      <v-text-field
                        readonly
                        min="0"
                        v-model="data.accomodation_charge"
                        label="Room Accomodation Charge"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" cols="12">
                      <v-text-field
                        readonly
                        min="0"
                        v-model="data.other_charges"
                        label="Other Charges"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" cols="12" v-if="data.other_charges > 0">
                      <v-text-field
                        min="0"
                        v-model="data.others_reason"
                        label="Specify (Others)"
                        dense
                        outlined
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" cols="12">
                      <v-text-field readonly v-model="data.total" label="Total" dense
                                    outlined></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
                <div v-if="data.mode_of_service==='Direct'">
                  <v-toolbar dense dark color="primary">
                    <v-toolbar-title><h4 class="font-weight-light">CALLERS INFORMATION</h4>
                    </v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                    <v-col md="4" cols="12"></v-col>
                    <v-row>
                      <v-col md="6" cols="12">
                        <v-text-field v-model="data.callers" label="Caller's Amount" readonly dense
                                      outlined></v-text-field>
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-text-field v-model="callers_name" label="Callers" dense readonly
                                      outlined></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </div>
              </v-card>
            </v-row>
          </div>
          <div v-else>
            <v-row>
              <v-col md="3" cols="12">
                <v-text-field readonly v-model="branch" label="Branch" dense
                              outlined></v-text-field>
              </v-col>
              <v-col md="3" cols="12">
                <v-text-field
                  v-model="data.fsc_no"
                  label="FSC No."
                  dense
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
              <v-col md="3" cols="12">
                <v-text-field

                  v-model="date_of_service"
                  label="Date of Service"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col md="3" cols="12">
                <v-text-field

                  v-model="date_of_internment"
                  label="Date of Interment"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-card flat>
                <v-toolbar dense dark color="primary">
                  <v-toolbar-title><h4 class="font-weight-light">DECEASED PERSONAL INFOMATION</h4>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-col md="4" cols="12"></v-col>
                  <v-row>
                    <v-col md="4" cols="12">
                      <v-text-field
                        v-model="last_name"
                        label="Last Name"
                        dense
                        outlined

                      ></v-text-field>
                    </v-col>

                    <v-col md="4" cols="12">
                      <v-text-field
                        v-model="first_name"
                        label="First Name"
                        dense
                        outlined

                      ></v-text-field>
                    </v-col>
                    <v-col md="4" cols="12">
                      <v-text-field
                        v-model="middle_name"
                        label="Middle Name"
                        dense
                        outlined

                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="5">
                      <v-text-field
                        v-model="date_of_birth"
                        label="Date of Birth"
                        dense
                        outlined

                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-text-field v-model="age" label="Age" dense outlined
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="5">
                      <v-text-field
                        v-model="date_of_death"
                        label="Date of Death"
                        dense
                        outlined

                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="religion"
                        dense
                        outlined
                        label="Religion"

                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field v-model="civil_status" label="Civil Status" dense
                                    outlined></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field v-model="gender" label="Gender" dense
                                    outlined></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-card flat>
                <v-toolbar dense dark color="primary">
                  <v-toolbar-title
                  ><h4 class="font-weight-light">FAMILY REPRESENTATIVES INFORMATION</h4>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-col md="4" cols="12"></v-col>
                  <v-row>
                    <v-col md="4" cols="12">
                      <v-text-field
                        v-model="representatives_name"
                        label="Name"
                        dense
                        outlined

                      ></v-text-field>
                    </v-col>

                    <v-col md="4" cols="12">
                      <v-text-field
                        v-model="representatives_relationship"
                        label="Relationship"
                        dense
                        outlined

                      ></v-text-field>
                    </v-col>
                    <v-col md="4" cols="12">
                      <v-text-field
                        v-model="representatives_contact_no"
                        label="Contact #"
                        dense
                        outlined

                      ></v-text-field>
                    </v-col>
                    <v-col md="12" cols="12">
                      <v-text-field
                        v-model="representatives_address"
                        label="Address"
                        dense
                        outlined

                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-toolbar dense dark color="primary">
                  <v-toolbar-title><h4 class="font-weight-light">SERVICE DATA INFORMATION</h4>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-col md="4" cols="12"></v-col>
                  <v-row>
                    <v-col cols="12" md="3">
                      <v-text-field v-model="casket_type" label="Casket Type" dense
                                    outlined></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field v-model="data.mode_of_service" label="Mode of Service" readonly
                                    dense
                                    outlined></v-text-field>
                    </v-col>
                    <v-col md="3" cols="12"
                           v-if="data.mode_of_service==='Damayan'||data.mode_of_service==='Damayan HG Package'||data.mode_of_service==='Damayan FG Package'">
                      <v-text-field
                        v-model="maf_no"
                        label="Maf #"
                        dense
                        outlined

                      ></v-text-field>
                    </v-col>
                    <v-col md="3" cols="12"
                           v-if="data.mode_of_service==='Damayan'||data.mode_of_service==='Damayan HG Package'||data.mode_of_service==='Damayan FG Package'">
                      <v-text-field
                        v-model="damayan_branch"
                        label="Damayan Branch"
                        dense
                        outlined

                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3"
                           v-if="data.mode_of_service==='Kaagapay'||data.mode_of_service==='Kaagapay Mem'||data.mode_of_service==='Kaagapay IFam'||data.mode_of_service==='Kaagapay Rel'||data.mode_of_service==='Kaagapay N-Rel'">
                      <v-text-field v-model="data.kaagapay_category" label="Kaagapay Category"
                                    readonly
                                    dense
                                    outlined></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3"
                           v-if="data.mode_of_service==='Kaagapay'||data.mode_of_service==='Kaagapay Mem'||data.mode_of_service==='Kaagapay IFam'||data.mode_of_service==='Kaagapay Rel'||data.mode_of_service==='Kaagapay N-Rel'">
                      <v-text-field v-model="data.kaagapay.kmf_no" label="KMF #" readonly
                                    dense
                                    outlined></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3"
                           v-if="data.mode_of_service==='Kaagapay'||data.mode_of_service==='Kaagapay Mem'||data.mode_of_service==='Kaagapay IFam'||data.mode_of_service==='Kaagapay Rel'||data.mode_of_service==='Kaagapay N-Rel'">
                      <v-text-field v-model="data.kaagapay.last_name" label="Kaagapay Last Name"
                                    readonly
                                    dense
                                    outlined></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3"
                           v-if="data.mode_of_service==='Kaagapay'||data.mode_of_service==='Kaagapay Mem'||data.mode_of_service==='Kaagapay IFam'||data.mode_of_service==='Kaagapay Rel'||data.mode_of_service==='Kaagapay N-Rel'">
                      <v-text-field v-model="data.kaagapay.first_name" label="Kaagapay First Name"
                                    readonly
                                    dense
                                    outlined></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3"
                           v-if="data.mode_of_service==='Kaagapay'||data.mode_of_service==='Kaagapay Mem'||data.mode_of_service==='Kaagapay IFam'||data.mode_of_service==='Kaagapay Rel'||data.mode_of_service==='Kaagapay N-Rel'">
                      <v-text-field v-model="data.kaagapay.middle_name" label="Kaagapay Middle Name"
                                    readonly
                                    dense
                                    outlined></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3" v-if="data.mode_of_service==='Tie Up'">
                      <v-text-field v-model="data.tie_ups" label="Tie Ups" readonly dense
                                    outlined></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field v-model="data.description" label="Description" readonly dense
                                    outlined></v-text-field>
                    </v-col>
                    <v-col md="3" cols="12">
                      <v-text-field

                        v-model="casket_price"
                        label="Casket Price"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" cols="12">
                      <v-text-field

                        v-model="casket_production"
                        label="Casket Production"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" cols="12">
                      <v-text-field

                        v-model="discount"
                        label="Casket Discount"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" cols="12">
                      <v-text-field

                        v-model="casket_price_net"
                        label="Casket Net Price"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" cols="12">
                      <v-text-field

                        min="0"
                        v-model="kilometer"
                        label="Kilometers"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" cols="12">
                      <v-text-field

                        v-model="kilometer_excess"
                        label="Kilometers Excess"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" cols="12">
                      <v-text-field

                        min="0"
                        v-model="days_of_embalming"
                        label="Day's of Embalming"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" cols="12">
                      <v-text-field

                        v-model="embalming_excess"
                        label="Embalming Excess"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field v-model="viewing_type" label="Viewing Type" dense
                                    outlined></v-text-field>
                    </v-col>
                    <v-col md="3" cols="12">
                      <v-text-field

                        min="0"
                        v-model="chapel_view"
                        label="Viewing Days"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" cols="12">
                      <v-text-field

                        v-model="viewing_excess"
                        label="Viewing Excess"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" cols="12">
                      <v-text-field

                        min="0"
                        v-model="shipment_fee"
                        label="Shipment Fee"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" cols="12">
                      <v-text-field

                        min="0"
                        v-model="tribute_fee"
                        label="Tribute Fee"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" cols="12">
                      <v-text-field

                        min="0"
                        v-model="hospital_bill"
                        label="Hospital Bill"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" cols="12">
                      <v-text-field

                        min="0"
                        v-model="accomodation_charge"
                        label="Room Accomodation Charge"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" cols="12">
                      <v-text-field

                        min="0"
                        v-model="other_charges"
                        label="Other Charges"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" cols="12" v-if="other_charges > 0">
                      <v-text-field
                        min="0"
                        v-model="others_reason"
                        label="Specify (Others)"
                        dense
                        outlined

                      ></v-text-field>
                    </v-col>
                    <v-col md="3" cols="12">
                      <v-text-field v-model="total" label="Total" dense
                                    outlined></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-checkbox v-if="data.mode_of_service === 'Direct'" label="Have Callers"
                            v-model="is_have_callers" hide-details
                            class="me-3 mt-1"></v-checkbox>
                <div v-if="data.mode_of_service==='Direct' && is_have_callers">
                  <v-toolbar dense dark color="primary">
                    <v-toolbar-title><h4 class="font-weight-light">CALLERS INFORMATION</h4>
                    </v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                    <v-col md="4" cols="12"></v-col>
                    <v-row>
                      <v-col md="6" cols="12">
                        <v-text-field v-model="callers" label="Caller's Amount" dense
                                      outlined></v-text-field>
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-text-field v-model="callers_name" label="Callers" dense readonly
                                      outlined></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-checkbox v-model="is_change_callers" label="Is Change Callers"
                                    hide-details
                                    class="mb-4"></v-checkbox>
                        <v-select
                          v-if="is_change_callers"
                          v-model="callers_id"
                          dense
                          outlined
                          label="Caller's"
                          :items="callers_items"
                          item-value="id"
                          item-text="full_name"
                          :rules="rules.combobox_rule"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </div>
              </v-card>
            </v-row>
          </div>
          <!-- alert -->
          <v-col cols="12" v-show="alert">
            <v-alert color="warning" text class="mb-0">
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{ alert_message }}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>

          <v-col cols="12" v-if="is_admin_special">
            <v-btn color="primary" class="me-3 mt-4" @click="save_member" v-if="!saving"> Save
              changes
            </v-btn>
            <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                 v-else></v-progress-circular>
          </v-col>
        </v-form>
      </v-card-text>
    </v-card>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline} from '@mdi/js'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import {mapActions, mapGetters} from "vuex";

  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        is_admin_special: false,
        saving: false,
        alert: false,
        alert_message: '',
        casket_price: 0,

        is_change_callers: false,

        callers_id: '',
        callers_items: [],

        accomodation_charge: 0,
        last_name: '',
        first_name: '',
        middle_name: '',
        date_of_birth: '',
        age: 0,
        gender: '',
        civil_status: '',
        religion: '',
        representatives_name: '',
        representatives_address: '',
        representatives_contact_no: '',
        representatives_relationship: '',
        date_of_death: '',
        date_of_internment: '',
        date_of_service: '',
        mode_of_service: '',
        kaagapay_category: '',
        damayan_branch: '',
        maf_no: '',
        casket_type: '',
        casket_production: 0,
        po_no: '',
        description: '',
        kilometer: 25,
        upgrade_service: 0,
        casket_price_net: 0,
        discount: 0,
        casket_price_format: '',
        casket_price_discount_format: '',
        callers: 0,
        kilometer_excess: 0,
        chapel_view_free_days: 0,
        embalming_free_days: 0,
        embalming_excess_charge: 0,
        days_of_embalming: 9,
        embalming_excess: 0,
        viewing_type_room: '',
        viewing_type: '',
        viewing_type_excess_charge: 0,
        chapel_view: 0,
        viewing_excess: 0,
        other_charges: 0,
        others_reason: '',
        special_price: 0,
        shipment_fee: 0,
        tribute_fee: 0,
        hospital_bill: 0,
        ho_share: 0,
        total: 0,
        is_have_callers: false,
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
        },
      }
    },
    props: {
      cropped: String,
      branch: String,
      data: Object,
      callers_name: String,
    },
    watch: {
      data: function () {
        const data3 = new FormData()
        data3.append('branch_id', this.branch_id)
        data3.append('positions', 'Callers')
        this.find_staff_with_position_active(data3)
          .then(response => {
            this.callers_items = response.data
          })
          .catch(error => {
            this.saving = false
          })

        this.casket_price = parseFloat(this.data.casket_price) + parseFloat(this.data.discount)
        this.position === 'ADMIN SPECIAL' ? this.is_admin_special = true : this.is_admin_special = false

        this.accomodation_charge = this.data.accomodation_charge
        this.last_name = this.data.last_name
        this.first_name = this.data.first_name
        this.middle_name = this.data.middle_name
        this.date_of_birth = this.data.date_of_birth
        this.age = this.data.age
        this.gender = this.data.gender
        this.civil_status = this.data.civil_status
        this.religion = this.data.religion
        this.representatives_name = this.data.representatives_name
        this.representatives_address = this.data.representatives_address
        this.representatives_contact_no = this.data.representatives_contact_no
        this.representatives_relationship = this.data.representatives_relationship
        this.date_of_death = this.data.date_of_death
        this.date_of_internment = this.data.date_of_internment
        this.date_of_service = this.data.date_of_service
        this.mode_of_service = this.data.mode_of_service
        this.kaagapay_category = this.data.kaagapay_category
        this.damayan_branch = this.data.damayan_branch
        this.maf_no = this.data.maf_no
        this.casket_type = this.data.casket_type
        this.casket_production = this.data.casket_production
        this.po_no = this.data.po_no
        this.description = this.data.description
        this.kilometer = this.data.kilometer
        this.upgrade_service = this.data.upgrade_service
        this.casket_price_net = this.data.casket_price
        this.discount = this.data.discount
        this.callers = this.data.callers
        this.kilometer_excess = this.data.kilometer_excess
        this.chapel_view_free_days = this.data.chapel_view_free_days
        this.embalming_free_days = this.data.embalming_free_days
        this.embalming_excess_charge = this.data.embalming_excess_charge
        this.days_of_embalming = this.data.days_of_embalming
        this.embalming_excess = this.data.embalming_excess
        this.viewing_type_room = this.data.viewing_type_room
        this.viewing_type = this.data.viewing_type
        this.viewing_type_excess_charge = this.data.viewing_type_excess_charge
        this.chapel_view = this.data.chapel_view
        this.viewing_excess = this.data.viewing_excess
        this.other_charges = this.data.other_charges
        this.others_reason = this.data.others_reason
        this.special_price = this.data.special_price
        this.shipment_fee = this.data.shipment_fee
        this.tribute_fee = this.data.tribute_fee
        this.hospital_bill = this.data.hospital_bill
        this.ho_share = this.data.ho_share
        this.total = this.data.total
        this.callers_id = this.data.callers_id
        this.is_have_callers = this.callers_id > 0
      }
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
      ...mapGetters('authentication', [
        'position',
        'branch_id',
      ]),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('staff', ['find_staff_with_position_active']),
      ...mapActions('deceased_client', ['update_details_for_admin_only']),
      save_member() {
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('id', this.data.id)
          data.append('callers_id', this.is_have_callers ? this.callers_id : '')
          data.append('last_name', this.last_name.toUpperCase())
          data.append('first_name', this.first_name.toUpperCase())
          data.append('middle_name', this.middle_name.toUpperCase())
          data.append('date_of_birth', this.date_of_birth)
          data.append('age', this.age)
          data.append('gender', this.gender)
          data.append('civil_status', this.civil_status)
          data.append('religion', this.religion.toUpperCase())
          data.append('representatives_name', this.representatives_name.toUpperCase())
          data.append('representatives_address', this.representatives_address.toUpperCase())
          data.append('representatives_contact_no', this.representatives_contact_no.toUpperCase())
          data.append('representatives_relationship', this.representatives_relationship.toUpperCase())
          data.append('date_of_death', this.date_of_death)
          data.append('date_of_internment', this.date_of_internment)
          data.append('mode_of_service', this.mode_of_service)
          data.append('date_of_service', this.date_of_service)
          data.append('damayan_branch', this.damayan_branch)
          data.append('maf_no', this.maf_no)
          data.append('casket_type', this.casket_type)
          data.append('casket_production', this.casket_production)
          data.append('description', this.description)
          data.append('kilometer', this.kilometer)
          data.append('casket_price', this.casket_price_net)
          data.append('callers', this.is_have_callers ? this.callers : 0)
          data.append('kilometer_excess', this.kilometer_excess)
          data.append('days_of_embalming', this.days_of_embalming)
          data.append('embalming_excess', this.embalming_excess)
          data.append('viewing_type', this.viewing_type_room)
          data.append('chapel_view', this.chapel_view)
          data.append('viewing_excess', this.viewing_excess)
          data.append('other_charges', this.other_charges)
          data.append('others_reason', this.others_reason)
          data.append('shipment_fee', this.shipment_fee)
          data.append('tribute_fee', this.tribute_fee)
          data.append('hospital_bill', this.hospital_bill)
          data.append('total', this.total)
          data.append('discount', this.discount)
          this.update_details_for_admin_only(data)
            .then(response => {
              var color = 'success'
              if (response.status === 201) {
                color = 'error'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error
              this.saving = false
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      },
    }
  }
</script>
