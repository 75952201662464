<template>
  <div>
    <v-card flat class="pt-4">
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">STATEMENT OF ACCOUNT</h4></v-toolbar-title>
        <v-spacer></v-spacer>
        Balance: {{balance_formated}}
        <v-spacer></v-spacer>
        <v-btn color="info" @click="print_soa"
               v-if="!saving && Object.keys(deceased_data).length > 0"> Print SOA
        </v-btn>
        <v-progress-circular :size="30" :width="5" color="info" indeterminate
                             v-else></v-progress-circular>
      </v-toolbar>
      <v-simple-table dense class="pt-2">
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-center text-uppercase">OR Date</th>
            <th class="text-center text-uppercase">OR No</th>
            <th class="text-center text-uppercase">Amount</th>
            <th class="text-center text-uppercase">Payment From</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in data" :key="item.id">
            <td class="text-center">
              {{ item.or_date }}
            </td>
            <td class="text-center">
              {{ item.or_no }}
            </td>
            <td class="text-center">
              {{ formatPrice(item.amount) }}
            </td>
            <td class="text-center">
              {{ item.payment_from }}
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
</template>

<script>
  import moment from 'moment'
  import {mapGetters} from 'vuex'

  export default {
    props: {
      data: Array,
      data_refund: Array,
      room_data: Array,
      deceased_data: Object,
      balance_formated: String,
    },
    computed: {
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
      ...mapGetters('authentication', [
        'branch',
        'branch_id',
        'year',
        'month_start',
        'month_end',
        'month_of',
        'user_id',
        'branch_address',
        'branch_contact_no',
      ]),
    },
    data() {
      return {
        saving: false,
      }
    },
    methods: {
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      print_soa() {
        this.saving = true
        var balance = this.deceased_data.total
        var imgData = this.company_logo
        var accomodation_array = []
        accomodation_array.push([
          {text: 'Date Stared', alignment: 'center', style: 'label',},
          {text: 'No. of Nights', alignment: 'center', style: 'label'},
          {text: 'Amount', alignment: 'center', style: 'label'},
        ])
        if (this.room_data.length > 0) {
          this.room_data.forEach(function (item) {
            accomodation_array.push([
              {text: item.date_started, alignment: 'center', style: 'tableExample'},
              {text: item.no_of_nights + ' DAY/S', alignment: 'center', style: 'tableExample'},
              {
                text: (item.amount / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'center', style: 'tableExample'
              },
            ])
          })
        }
        accomodation_array.push([{text: '****', style: 'tableExample2'}, {
          text: '****',
          style: 'tableExample2'
        }, {text: '****', style: 'tableExample2'}])
        var payment = 0
        var payments_array = []
        payments_array.push([
          {text: 'OR Date', alignment: 'center', style: 'label'},
          {text: 'OR No', alignment: 'center', style: 'label'},
          {text: 'Amount', alignment: 'center', style: 'label'},
          {text: 'Payment From', alignment: 'center', style: 'label'},
        ])
        if (this.data.length > 0) {
          this.data.forEach(function (item) {
            if (!item.payment_from.includes("DAMAYAN")) {
              payment += parseFloat(item.amount)

              var or_no = item.or_no
              var splitedOr = item.or_no.split('-')
              if (splitedOr.length > 0) {
                or_no = splitedOr[0]
              }
              payments_array.push([
                {text: item.or_date, alignment: 'center', style: 'tableExample'},
                {text: or_no, alignment: 'center', style: 'tableExample'},
                {
                  text: (item.amount / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'center', style: 'tableExample'
                },
                {text: item.payment_from, alignment: 'center', style: 'tableExample'},
              ])
            }
          })
        }
        payments_array.push([{text: '****', style: 'tableExample2'}, {
          text: '****',
          style: 'tableExample2'
        }, {text: '****', style: 'tableExample2'}, {text: '****', style: 'tableExample2'}])
        var refunds_amount = 0
        var refunds_array = []
        refunds_array.push([
          {text: 'Claimant', alignment: 'center', style: 'label'},
          {text: 'Fund From', alignment: 'center', style: 'label'},
          {text: 'Voucher #', alignment: 'center', style: 'label'},
          {text: 'Amount', alignment: 'center', style: 'label'},
          {text: 'Check #', alignment: 'center', style: 'label'},
          {text: 'Date Released', alignment: 'center', style: 'label'},
        ])
        if (this.data_refund.length > 0) {
          this.data_refund.forEach(function (item) {
            refunds_amount += item.amount
            refunds_array.push([
              {text: item.claimant, alignment: 'center', style: 'tableExample'},
              {text: item.fund_from, alignment: 'center', style: 'tableExample'},
              {text: item.voucher_no, alignment: 'center', style: 'tableExample'},
              {
                text: (item.amount / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'center', style: 'tableExample'
              },
              {text: item.check_no, alignment: 'center', style: 'tableExample'},
              {text: item.date_of_released, alignment: 'center', style: 'tableExample'},
            ])
          })
        }
        refunds_array.push([{text: '****', style: 'tableExample2'}, {
          text: '****',
          style: 'tableExample2'
        }, {text: '****', style: 'tableExample2'}, {
          text: '****',
          style: 'tableExample2'
        }, {text: '****', style: 'tableExample2'}, {text: '****', style: 'tableExample2'}])

        var totall = parseFloat(this.deceased_data.total)
        var damayan_data = [{
          text: ['-\n-'
          ],
        }, {
          text: ['-\n-'
          ],
        }, {
          text: ['-\n-'
          ],
        },]

        if (this.deceased_data.damayan_category != '') {
          if (this.deceased_data.damayan_category === 'WAIVED') {
            damayan_data = [
              {
                text: [''
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Damayan Discount: ',
                  {
                    text: this.formatPrice(parseFloat(this.deceased_data.payable_on_damayan)),
                    style: {
                      fontSize: 11,
                      color: 'blue',
                      bold: true,
                      margin: [0, 2, 0, 2],
                      alignment: 'left',
                    },
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Client Chapel Payables: ',
                  {
                    text: (this.deceased_data.mode_of_service === 'Damayan HG Package' || this.deceased_data.mode_of_service === 'Damayan FG Package') ? 0 : this.formatPrice(parseFloat(this.deceased_data.casket_price) - parseFloat(this.deceased_data.payable_on_damayan)),
                    style: {
                      fontSize: 11,
                      color: 'green',
                      bold: true,
                      margin: [0, 2, 0, 2],
                      alignment: 'left',
                    },
                  },
                ],
              },
            ];
          } else {
            damayan_data = [
              {
                text: [''
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Damayan Payable: ',
                  {
                    text: this.formatPrice((parseFloat(this.deceased_data.payable_on_damayan))),
                    style: {
                      fontSize: 11,
                      color: 'red',
                      bold: true,
                      margin: [0, 2, 0, 2],
                      alignment: 'left',
                    },
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Client Chapel Payables: ',
                  {
                    text: (this.deceased_data.mode_of_service === 'Damayan HG Package' || this.deceased_data.mode_of_service === 'Damayan FG Package') ? 0 : this.formatPrice(parseFloat(this.deceased_data.casket_price) - (parseFloat(this.deceased_data.payable_on_damayan))),
                    style: {
                      fontSize: 11,
                      color: 'green',
                      bold: true,
                      margin: [0, 2, 0, 2],
                      alignment: 'left',
                    },
                  },
                ],
              },
            ];
          }
        }
        balance = (parseFloat(totall) + parseFloat(refunds_amount)) - parseFloat(payment)
        var service_fee = [
          {
            columns: [
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      'Mode: ',
                      {
                        text: this.deceased_data.mode_of_service,
                        style: 'main_data',
                      },
                    ],
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Casket Type: ',
                  {
                    text: this.deceased_data.casket_type,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Discription: ',
                  {
                    text: this.deceased_data.description,
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'Casket Price: ',
                  {
                    text: this.formatPrice((this.deceased_data.mode_of_service === 'Damayan HG Package' || this.deceased_data.mode_of_service === 'Damayan FG Package') ? 0 : parseFloat(this.deceased_data.casket_price) + parseFloat(this.deceased_data.discount)),
                    style: {
                      fontSize: 11,
                      color: 'red',
                      bold: true,
                      margin: [0, 2, 0, 2],
                      alignment: 'left'
                    },
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Casket Discount: ',
                  {
                    text: this.formatPrice(this.deceased_data.discount),
                    style: {
                      fontSize: 11,
                      color: 'blue',
                      bold: true,
                      margin: [0, 2, 0, 2],
                      alignment: 'left'
                    },
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Casket Net Price: ',
                  {
                    text: this.formatPrice((this.deceased_data.mode_of_service === 'Damayan HG Package' || this.deceased_data.mode_of_service === 'Damayan FG Package') ? 0 : parseFloat(this.deceased_data.casket_price)),
                    style: {
                      fontSize: 11,
                      color: 'orange',
                      bold: true,
                      margin: [0, 2, 0, 2],
                      alignment: 'left'
                    },
                  },
                ],
              },
            ],
          },
          {
            columns: damayan_data,
          },
        ]
        var data_prices = [
          {
            columns: [
              {
                style: 'main_info',
                text: [''
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Kilometer Excess: ',
                  {
                    text: this.formatPrice(this.deceased_data.kilometer_excess),
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Tribute Fee: ',
                  {
                    text: this.formatPrice(this.deceased_data.tribute_fee),
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [''
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Embalming Excess: ',
                  {
                    text: this.formatPrice(this.deceased_data.embalming_excess),
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Hospital Bill: ',
                  {
                    text: this.formatPrice(this.deceased_data.hospital_bill),
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'Kilometer: ',
                  {
                    text: this.deceased_data.kilometer + ' KM/s',
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Viewing Excess: ',
                  {
                    text: this.formatPrice(this.deceased_data.viewing_excess),
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Accomodation Charge: ',
                  {
                    text: this.formatPrice(this.deceased_data.accomodation_charge),
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'Days of Embalming: ',
                  {
                    text: this.deceased_data.days_of_embalming + ' DAY/s',
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Other Charges: ',
                  {
                    text: this.formatPrice(this.deceased_data.other_charges),
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: ['Specify(Others): '],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'Chapel Viewing: ',
                  {
                    text: this.deceased_data.chapel_view + ' DAY/s',
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Special Price: ',
                  {
                    text: this.formatPrice(this.deceased_data.special_price),
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  {
                    text: this.deceased_data.others_reason,
                    style: 'main_data_2',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'Viewing at: ',
                  {
                    text: this.deceased_data.viewing_type,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Shipment Fee: ',
                  {
                    text: this.formatPrice(this.deceased_data.shipment_fee),
                    style: 'main_data',
                  },
                ],
              },
              {},
            ],
          },
          {
            columns: [
              {
                style: {fontSize: 15},
                text: [
                  'Php Total: ',
                  {
                    text: this.formatPrice(totall),
                    style: {fontsize: 20, color: 'red', bold: true},
                  },
                ],
              },
              {
                style: {fontSize: 15},
                text: [
                  'Balance: ',
                  {
                    text: this.formatPrice(balance),
                    style: {fontsize: 20, color: 'red', bold: true},
                  },
                ],
              },
            ],
          },
        ]
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs
        }
        var docDefinition = {
          pageSize: {
            width: 612,
            height: 936,
          },
          pageOrientation: 'portrait',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE MEMORIAL CHAPEL CO.',
                    {text: this.branch_address, style: 'subheader'},
                    {
                      text: 'S.E.C No. PG201636035 TEL #: (082)333-1809 CONTACT NO.: ' + this.branch_contact_no,
                      style: 'subheader',
                    },
                    {
                      text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                      style: 'printed_label',
                    },
                  ],
                  style: 'header',
                },
              ],
            },
            '_______________________________________________________________________________________________',
            {
              stack: [
                {
                  columns: [
                    {text: 'Branch: ' + this.branch, style: 'main_info'},
                    {
                      text: 'STATEMENT OF ACCOUNT (SOA)',
                      style: {
                        fontSize: 16,
                        alignment: 'center',
                        bold: true,
                        color: 'blue',
                      },
                    },
                    {
                      text: 'FSC No.: ' + this.deceased_data.fsc_no,
                      style: {
                        margin: [0, 2, 0, 2],
                        fontSize: 13,
                        alignment: 'right',
                        color: 'red',
                        bold: true,
                      },
                    },
                  ],
                },
              ],
            },
            '_______________________________________________________________________________________________',
            {
              stack: [
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'Date of Service: ',
                        {
                          text: moment(this.deceased_data.date_of_service, 'YYYY-MM-DD').format('MMMM D, YYYY'),
                          style: 'main_data_2',
                        },
                      ],
                    },
                    {text: ''},
                    {
                      style: 'main_info',
                      text: [
                        'Date of Interment: ',
                        {
                          text: moment(this.deceased_data.date_of_internment, 'YYYY-MM-DD').format('MMMM D, YYYY'),
                          style: 'main_data_2',
                        },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'Name of Cadaver: ',
                        {
                          text:
                            this.deceased_data.last_name +
                            ', ' +
                            this.deceased_data.first_name +
                            ' ' +
                            this.deceased_data.middle_name,
                          style: 'main_data',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            '_______________________________________________________________________________________________',
            {
              stack: [
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: ['Gender: ', {text: this.deceased_data.gender, style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [
                        'Date of Birth: ',
                        {
                          text: moment(this.deceased_data.date_of_birth, 'YYYY-MM-DD').format('MMMM D, YYYY'),
                          style: 'main_data_2',
                        },
                      ],
                    },
                    {
                      style: 'main_info',
                      text: [
                        'Age: ',
                        {
                          text: this.deceased_data.age,
                          style: 'main_data',
                        },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'Civil Status: ',
                        {
                          text: this.deceased_data.civil_status,
                          style: 'main_data',
                        },
                      ],
                    },
                    {
                      style: 'main_info',
                      text: [
                        'Date of Death: ',
                        {
                          text: moment(this.deceased_data.date_of_death, 'YYYY-MM-DD').format('MMMM D, YYYY'),
                          style: 'main_data_2',
                        },
                      ],
                    },
                    {text: ''},
                  ],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'Religion: ',
                        {
                          text: this.deceased_data.religion,
                          style: 'main_data',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            '_______________________________________________________________________________________________',
            'FAMILY REPRESENTATIVES',
            {
              stack: [
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'Name: ',
                        {
                          text: this.deceased_data.representatives_name,
                          style: 'main_data',
                        },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'Relationship: ',
                        {
                          text: this.deceased_data.representatives_relationship,
                          style: 'main_data',
                        },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'Address: ',
                        {
                          text: this.deceased_data.representatives_address,
                          style: 'main_data',
                        },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'Contact No.: ',
                        {
                          text: this.deceased_data.representatives_contact_no,
                          style: 'main_data',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            '__________________________________________________________________________________________________',
            'SUMMARY OF SERVICE',
            {
              stack: service_fee,
            },
            '__________________________________________________________________________________________________',
            'SUMMARY OF CHARGES',
            {
              stack: data_prices,
            },
            {
              columns: [
                {
                  text: ' ',
                },
              ],
            },
            'ROOM ACCOMODATION HISTORY',
            {
              table: {
                widths: [80, 80, 80],
                body: accomodation_array,
                style: {fontSize: 1},
              },
            },
            {
              columns: [
                {
                  text: ' ',
                },
              ],
            },
            'PAYMENT HISTORY',
            {
              table: {
                widths: [80, 80, 80, 200],
                body: payments_array,
                style: {fontSize: 1},
              },
            },
            {
              columns: [
                {
                  text: ' ',
                },
              ],
            },
            'REFUNDS HISTORY',
            {
              table: {
                widths: [150, 80, 60, 50, 50, 80],
                body: refunds_array,
                style: {fontSize: 1},
              },
            },
            {
              stack: [
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: this.deceased_data.chapel_manager,
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_________________________________________',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'CASHIER',
                    },
                    {
                      text: 'CHAPEL MANAGER',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: 'AUDITOR',
                    },
                  ],
                },
              ],
            },
          ],
          footer: {
            // columns: [
            //     {
            //         text: 'Generated with Goodlife Savings & Credit Cooperative System',
            //         alignment: 'center',
            //         style: 'tableExample'
            //     }
            // ]
          },
          styles: {
            tableExample: {
              fontSize: 9,
            },
            tableExample2: {
              fontSize: 7,
            },
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 5], //[left, top, right, bottom]
            },
            subheader: {
              fontSize: 11,
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            main_data: {
              margin: [0, 2, 0, 2],
              fontSize: 11,
              bold: true,
              alignment: 'left',
            },
            main_data_2: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
              bold: true,
              alignment: 'left',
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
              alignment: 'left',
            },
          },
        }
        pdfMake.createPdf(docDefinition).open()
        this.saving = false
      },
    },
  }
</script>
