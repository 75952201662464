<template>
  <div>
    <v-card flat>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">SEARCH DECEASED</h4></v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-combobox
          class="mt-6"
          :append-icon="icons.mdiAccountSearchOutline"
          v-model="search"
          @keyup="searching($event.target.value)"
          @change="get_search_items_info"
          :items="search_items"
          item-value="id"
          item-text="name"
          placeholder="Search by (Account Name/Account #)"
          ref="memberCombobox"
          dense
          outlined
        ></v-combobox>
      </v-card-text>
      <v-tabs v-model="tab" show-arrows>
        <v-tab v-for="tab in tabs" :key="tab.title">
          <v-icon size="20" class="me-3">
            {{ tab.icon }}
          </v-icon>
          <span>{{ tab.title }}</span>
        </v-tab>
      </v-tabs>
      <!-- tabs item -->
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <account :cropped="cropped" :branch="branch" :data="deceased_information"
                   :callers_name="callers_name"></account>
        </v-tab-item>
        <v-tab-item>
          <statement-of-account
            :data="payments_history"
            :data_refund="refund_history"
            :room_data="room_accomodation_history"
            :deceased_data="deceased_information"
            :balance_formated="balance"
          ></statement-of-account>
        </v-tab-item>
        <v-tab-item>
          <room-accomodation-history :data="room_accomodation_history"></room-accomodation-history>
        </v-tab-item>
        <v-tab-item>
          <refunds-history :data_refund="refund_history"
                           :deceased_data="deceased_information"
                           :balance="balance"></refunds-history>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
  import {
    mdiAccountOutline,
    mdiInformationOutline,
    mdiAccountCashOutline,
    mdiCash,
    mdiCellphone,
    mdiClipboardClockOutline,
    mdiContentSaveMoveOutline,
    mdiCashRefund,
    mdiShieldAccount,
    mdiGoogleCirclesCommunities,
    mdiBallotOutline,
    mdiAccountCash,
  } from '@mdi/js'
  import {mapGetters, mapActions} from 'vuex'
  import Account from './deceased_components/Account'
  import StatementOfAccount from './deceased_components/StatementOfAccount'
  import RoomAccomodationHistory from './deceased_components/RoomAccomodationHistory'
  import RefundsHistory from './deceased_components/RefundsHistory'

  export default {
    components: {
      Account,
      StatementOfAccount,
      RoomAccomodationHistory,
      RefundsHistory,
    },
    setup() {
      return {
        avatarImg: require('@/assets/images/avatars/1.png'),
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiInformationOutline,
          mdiCash,
          mdiCellphone,
          mdiClipboardClockOutline,
          mdiContentSaveMoveOutline,
          mdiCashRefund,
          mdiShieldAccount,
          mdiGoogleCirclesCommunities,
          mdiBallotOutline,
          mdiAccountCash,
        },
      }
    },
    data() {
      return {
        search: '',
        search_member: '',
        search_items: [],
        balance: '0',
        callers_name: '',

        cropped: this.avatarImg,
        deceased_information: {},
        payments_history: [],
        refund_history: [],
        room_accomodation_history: [],

        tab: '',
        tabs: [
          {title: 'INFORMATIONS', icon: mdiAccountOutline},
          {title: 'STATEMENT OF ACCOUNT', icon: mdiAccountCashOutline},
          {title: 'ROOM ACCOMODATION', icon: mdiInformationOutline},
          {title: 'REFUNDS', icon: mdiAccountCash},
        ],
      }
    },
    watch: {
      search_member: function () {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (this.search_member === '') {
            this.search_items = []
          } else {
            this.searching_search_items(this.search_member)
          }
        }, 500)
      },
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', [
        'branch',
        'branch_id',
        'year',
        'month_start',
        'month_end',
        'month_of',
        'user_id',
        'branch_address',
        'branch_contact_no',
      ]),
    },
    methods: {
      ...mapActions('deceased_client', ['search_deceased_client']),
      searching(value) {
        this.search_member = value
      },
      searching_search_items(value) {
        this.search_deceased_client({
          branch_id: this.branch_id,
          search_word: value,
        }).then(response => {
          this.search_items = response.data
        })
      },
      reset() {
        this.cropped = this.avatarImg
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      get_search_items_info() {
        this.amount = 0
        this.amount_due = []
        if (this.search_items.length > 0) {
          var index = this.search_items
            .map(function (x) {
              return x.id
            })
            .indexOf(this.search.id)
          if (index != -1) {
            this.callers_name = this.search_items[index].callers_name
            this.balance = this.formatPrice(this.search_items[index].balance)
            this.deceased_information = this.search_items[index].data
            this.payments_history = this.search_items[index].payments
            this.refund_history = this.search_items[index].refunds
            this.room_accomodation_history = this.search_items[index].room_accomodation
          } else {
            this.reset()
          }
        } else {
          this.reset()
        }
      },
    },
  }
</script>
