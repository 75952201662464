<template>
  <div>
    <v-card
      flat
      class="pt-4"
    >
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">STATEMENT OF ACCOUNT</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-simple-table dense class="pt-2">
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-center text-uppercase">
              Date Started
            </th>
            <th class="text-center text-uppercase">
              No. of Nights
            </th>
            <th class="text-center text-uppercase">
              Amount
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="item in data"
            :key="item.id"
          >
            <td class="text-center">
              {{ item.date_started }}
            </td>
            <td class="text-center">
              {{ item.no_of_nights }}
            </td>
            <td class="text-center">
              {{ formatPrice(item.amount) }}
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
</template>

<script>

  export default {
    props: {
      data: Array,
    },
  }
</script>
